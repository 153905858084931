:root {
	--color-primary: #cc1f1a;
	--color-primary-light: #ef5753;
	--color-primary-dark: #621b18;
	--color-grey-light-1: #faf9f9;
	--color-grey-light-2: #f4f2f2;
	--color-grey-light-3: #f0eeee;
	--color-grey-light-4: #ccc;
	--color-grey-dark-1: #404040;
	--color-grey-dark-2: #777;
	--color-grey-dark-3: #999;
	--color-red: #A52631;
	--color-green: #207245;
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
	--line: 1px solid var(--color-grey-light-2);
}
* {
	margin: 0 ;
	padding: 0 ;
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;
	font-size: 62.5%; 
}
@media only screen and (max-width: 68.75em) {
	html {
		font-size: 50%;
	} 
}
body {
	font-weight: 400;
	line-height: 1.6;
	color: var(--color-grey-dark-2);
	background-color: var(--color-grey-light-3);
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
}
body {
	font-family: 'Montserrat', sans-serif;
}
.input-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	font-size: 1.6rem;
	color: var(--color-grey-dark-1);
}
.input-container input {
	padding: 1rem;
	font-size: 1.6rem;
	outline: none;
	border: none;
	border-bottom: 1px solid var(--color-grey-dark-1);
	margin-top: .5rem;
	color: var(--color-grey-dark-3);
	background-color: transparent;
}
.input-container button {
	padding: 1rem;
	background-color: transparent;
	border: 2px solid var(--color-primary);
	font-size: 1.8rem;
	font-weight: 700;
	color: var(--color-primary);
	text-transform: uppercase;
	cursor: pointer;
	margin-top: .5rem;
	outline: none;
	transition: all .5s;
}
.input-container button:hover {
	background-color: var(--color-primary);
	color: #fff;
}

/* APP */
header {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: fixed;
	z-index: 99;
}
.header__pages {
	background-color: #fff;
	display: flex;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.header__pages-container {
	display: flex;
	align-items: center;
}
.header__pages-container a {
	padding: 1rem 2rem;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	filter: grayscale(1);
}
.header__pages-container a:hover {
	filter: grayscale(0);
}
.header__top {
	background-color: #fff;
	display: flex;
}
.header__top > a {
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 7rem;
}
.header__top > a img {
	height: 100%;
}
.header__top ul {
	display: flex;
	margin: auto;
	list-style: none;
}
.header__top ul li {
	margin: 0 2rem;
	display: flex;
	align-items: center;
	border: 1px solid var(--color-grey-light-4);
	padding: .3rem 1rem;
	border-radius: 4rem;
}
.header__top ul li i {
	color: var(--color-primary-dark);
	margin-right: 1rem;
}
.header__top ul li span {
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
}
.header__top ul li a {
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
	text-decoration: none;
}
.header__bottom {
	background-color: var(--color-grey-light-3);
	box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1);
	height: 4rem;
	display: flex;
	padding: 0 2.5rem;
}
.header__bottom > ul {
	display: flex;
	flex: 0 0 70%;
}
.header__bottom > ul > li {
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;
}
.header__bottom > ul > li:hover {
	background-color: #fff;
}
.header__bottom > ul > li > a {
	height: 4rem;
	display: flex;
	align-items: center;
	position: relative;
	padding: 0 1rem;
	font-size: 1.4rem;
	text-decoration: none;
	color: var(--color-grey-dark-1);
	font-weight: 700;
}
.header__bottom > ul > li:hover > a {
	color: var(--color-primary) !important;
}
.header__bottom > ul > a {
	flex: 1;
	height: 4rem;
	display: flex;
	align-items: center;
	padding: 0 1rem;
	font-size: 1.4rem;
	text-decoration: none;
	color: var(--color-grey-dark-1);
	font-weight: 700;
}
.header__bottom > ul > a:hover {
	background-color: #fff;
	color: var(--color-primary);
}
.header__bottom > ul > li > .options {
	display: none;
	position: absolute;
	top: 4rem;
	flex-direction: column;
	background-color: #fff;
	width: 100%;
	height: 25rem;
	overflow-y: auto;
	box-shadow: inset 0px -2px 3px rgba(0,0,0,.2);
}
.header__bottom > ul > li > .options > a {
	padding: .5rem;
	margin: .5rem;
	color: var(--color-grey-dark-1);
	font-size: 1.4rem;
	text-decoration: none;
}
.header__bottom > ul > li > .options > a:hover {
	color: var(--color-primary);
}
.header__bottom > ul > li:hover > .options {
	display: flex;
	flex-direction: column;
}
footer {
	background-color: var(--color-grey-dark-1);
}
.footer__container {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
}
.footer__top {
	padding: 9rem 0;
	display: flex;
	border-bottom: var(--line);
}
.footer__top ul {
	display: flex;
	flex-direction: column;
	flex: 1;
}
.footer__top ul > a {
	font-size: 1.4rem;
	color: #fff;
	text-decoration: none;
	font-weight: 700;
}
.footer__top ul .options {
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
}
.footer__top ul .options a {
	margin-bottom: 1rem;
	color: #fff;
	font-size: 1.4rem;
	text-decoration: none;
	padding: .5rem;
}
.footer__top ul .options a:hover {
	color: var(--color-primary);
}
.footer__bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.footer__bottom picture {
	padding: 1rem;
	height: 5rem;
}
.footer__bottom picture img {
	height: 100%;
} 
.footer__bottom ul {
	display: flex;
	color: var(--color-grey-light-2);
}
.footer__bottom ul a {
	margin-right: 1rem;
	border-bottom: 1px dashed var(--color-grey-dark-3);
	text-decoration: none;
	color: var(--color-grey-light-2);
}
.footer__contact {
	display: flex;
	flex-direction: column;
}
.footer__contact h4 {
	font-size: 1.4rem;
	color: #fff;
	text-decoration: none;
	font-weight: 700;
}
.footer__contact p {
	font-size: 1.2rem;
	color: #fff;
	margin-top: 1.5rem;
}
.footer__contact span {
	font-size: 1.4rem;
	color: var(--color-primary);
	margin-top: 1.5rem;
}
.footer__contact a {
	margin-top: 1.5rem;
	font-size: 1.2rem;
	color: #fff;
	text-decoration: none;
	border-bottom: 1px dotted #fff;
}

/* HOME */
.home {
	min-height: 100vh;
}
.home__cover {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	height: 70vh;
	background-image: url('/images/pnq1.png');
	background-size: cover;
	background-position: top;
}
.home__cover video {
	position: relative;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    /* top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    -webkit-transition: opacity 1s;
    -o-transition: 1s opacity;
    transition: opacity 1s; */
}
.home__cover h1 {
	position: absolute;
	font-size: 4.5rem;
	margin-top: -8.5rem;
	width: 100%;
	max-width: 50rem;
	text-align: center;
	letter-spacing: 3px;
	color: #fff;
	font-weight: 900;
	text-transform: uppercase;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, .8);
}
.home__cover p {
	position: absolute;
	font-size: 1.4rem;
	margin-top: -2.5rem;
	letter-spacing: 3px;
	color: #fff;
	font-weight: 900;
	text-transform: uppercase;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, .8);
	margin-left: 4.5rem;
}
.home__highlights {
	padding: 4.5rem;
	display: flex;
}
.home__highlights a {
	flex: 1;
	position: relative;
	display: flex;
	overflow: hidden;
}
.home__highlights a img {
	width: 100%;
	position: relative;
	transform: scale(1);
	transition: all .5s;
}
.home__highlights a .container {
	position: absolute;
	bottom: 0;
	padding: 2rem;
	background-color: rgba(0, 0, 0, .4);
	color: #fff;
	overflow: hidden;
	transition: all .5s;
}
.home__highlights a .container h3 {
	font-size: 1.8rem;
}
.home__highlights a .container h2 {
	font-size: 2.5rem;
	font-weight: 900;
}
.home__highlights a .container p {
	font-size: 1.4rem;
	text-align: center;
	height: 10rem;
	margin-bottom: -12rem;
	transition: all .5s;
	display: flex;
	align-items: center;
}
.home__highlights a:hover img {
	transform: scale(1.25);
}
.home__highlights a:hover .container p {
	margin: 0;
}
.home__highlights a:hover .container {
	background-color: rgba(0, 0, 0, .8);
}
.home__products {
	padding: 9rem 0;
	display: flex;
}
.home__products-container {
	display: flex;
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	overflow-y: auto;
}
.home__separator {
	background-color: #fff;
}
.home__separator-container {
	width: 100%;
	max-width: 100rem;
	display: flex;
	margin: 0 auto;
	align-items: center;
}
.home__separator-container .content {
	flex: 2;
	margin-right: 4.5rem;
	display: flex;
	flex-direction: column;
}
.home__separator-container .content span {
	color: var(--color-grey-dark-1);
	font-weight: 800;
	font-size: 1.4rem;
}
.home__separator-container .content h2 {
	color: var(--color-primary);
	font-size: 2.5rem;
}
.home__separator-container .content p {
	font-size: 1.4rem;
	margin-top: 2rem;
	color: var(--color-grey-dark-1);
}
.home__separator-container .content a {
	margin-top: 2.5rem;
	padding: 1rem 2rem;
	background-color: var(--color-primary);
	margin-right: auto;
	font-size: 1.2rem;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 800;
	letter-spacing: 2px;
	border-radius: .5rem;
}
.home__separator-container picture {
	flex: 1;
}
.home__separator-container picture img {
	width: 100%;
}
.home__social {
	padding: 4.5rem 0;
	display: flex;
}
.home__social-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
}
.home__social-item {
	flex: 1;
}
.home__social-item .title {
	border-bottom: 1px solid var(--color-grey-light-4);
	margin-bottom: 2rem;
}
.home__social-item .title b {
	font-size: 1.2rem;
	color: var(--color-grey-dark-1);
}
.home__social-item .title h4 {
	font-size: 3rem;
	color: var(--color-primary);
	text-transform: uppercase;
}
.home__contact {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
	background-color: #fff;
	position: relative;
	z-index: 3;
}
.home__contact-container {
    width: 120rem;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
}
.home__contact-container h3 {
    flex: 0 0 100%;
    text-align: center;
    padding: 0 0 4.5rem;
    font-size: 2.5rem;
    color: var(--color-primary-dark);
}
.home__contact-middle-container {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
}
.home__contact-middle-container form {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 2rem;
    justify-content: space-between;
}
.home__contact-middle-container form button {
    align-self: flex-start;
    padding: 1rem 4rem;
    border: 3px solid var(--color-grey-dark-2);
    color: var(--color-grey-dark-2);
    cursor: pointer;
    background-color: transparent;
    margin-left: 2rem;
    margin-top: 2rem;
    text-transform: uppercase;
}
.home__contact-middle-container form button:hover {
    background-color: var(--color-grey-dark-2);
    color: #fff;
}
.home__input-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
}
.home__input-container label {
    text-transform: uppercase;
}
.home__input-container input {
    padding: 1rem;
    color: var(--color-grey-dark-1);
    outline: none;
    background-color: #fff;
    border: 1px solid var(--color-grey-dark-3);
    margin-top: 1rem;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.home__input-container textarea {
    padding: 1rem;
    color: var(--color-grey-dark-1);
    outline: none;
    background-color: #fff;
    border: 1px solid var(--color-grey-dark-3);
    margin-top: 1rem;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.home__contact-middle-container h4 {
    font-size: 4.5rem;
    padding: 0 0 1rem;
    color: var(--color-grey-dark-1);
}
.home__contact-phone {
    display: flex;
    font-size: 3.5rem;
    align-items: center;
    font-weight: 600;
    margin-top: 2.5rem;
    color: var(--color-grey-dark-1);
}
.home__contact-phone i {
    margin-right: 1rem;
    color: var(--color-grey-light-1);
    background-color: var(--color-grey-dark-1);
    padding: 1rem;
    border-radius: 50%;
    font-size: 3rem;
}
.home__contact-middle-container > ul {
    list-style: none;
}
.home__contact-middle-container > ul > li {
    display: flex;
    flex-direction: column;
}
.home__contact-middle-container > ul > li > span {
    padding: 1rem 2rem;
}
.home__contact-middle-container > ul > li > ul {
    padding: 1rem 4rem;
}
.home__customers {
	padding: 9rem 0;
	display: flex;
	background-image: url('/images/ede9.png');
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
}
.home__customers-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.home__customers-container h3 {
	font-size: 2.5rem;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}
.home__customers-container ul {
	display: flex;
	flex-wrap: wrap;
}
.home__customers-container ul picture {
	display: flex;
	flex: 1 0 25%;
	padding: 2.5rem;
	align-items: center;
	justify-content: center;
}
.home__customers-container ul picture img {
	width: 12.5rem;
}
.home__social-item ul {
	display: flex;
	flex-wrap: wrap;
}
.home__social-item ul picture {
	flex: 1 0 28%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	height: 10rem;
	margin: 1rem;
}
.home__social-item ul picture img {
	height: 100%;
}

/* PRODUCTS */
.products {
	padding: 17rem 0 9rem;
	display: flex;
	min-height: 100vh;
}
.products__container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.products__container h1 {
	font-size: 2.5rem;
	color: var(--color-primary);
	letter-spacing: 3px;
	font-weight: 600;
	margin-bottom: 2rem;
}
.products__container form {
	display: flex;
	width: 100%;
	background-color: #fff;
	overflow: hidden;
	border-radius: 4rem;
	margin-bottom: 2rem;
	align-items: center;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
}
.products__container form input {
	flex: 1;
	border: none;
	padding: 1rem 2rem;
	background-color: transparent;
	outline: none;
	color: var(--color-grey-dark-1);
}
.products__container form i {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .5rem 1rem;
}
.products__container ul {
	display: flex;
	flex-wrap: wrap;
}

/* INDUSTRIES */
.industries {
	min-height: 100vh;
}
.industries__cover {
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url('/images/ede6.png');
	background-size: cover;
	background-position: center;
}
.industries__cover-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;
}
.industries__cover-container span {
	font-size: 2rem;
	font-weight: 500;
	text-transform: uppercase;
	color: #fff;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, .8);
}
.industries__cover-container h1 {
	font-size: 4.5rem;
	text-transform: uppercase;
	font-weight: 800;
	color: #fff;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, .8);
	letter-spacing: 3px;
}
.industries__cover-container p {
	font-size: 1.8rem;
	color: #fff;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, .8);
	margin-top: 1.5rem;
}
.industries__list {
	padding: 8rem 0;
	display: flex;
}
.industries__list-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
}
.industries__list-container a {
	flex: 1 0 30%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	max-width: 45rem;
}
.industries__list-container a img {
	position: relative;
	width: 100%;
}
.industries__list-container a .title {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
}
.industries__list-container a .title h3 {
	color: #fff;
	font-size: 3.5rem;
	text-transform: uppercase;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, .8);
	text-align: center;
}
.industries__list-container a .content {
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	background-color: var(--color-primary);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0;
	height: 0px;
	transition: all .2s;
	overflow: hidden;
}
.industries__list-container a:hover .content {
	height: 100%;
	padding: 2rem;
}
.industries__list-container a .content h3 {
	font-size: 2rem;
	color: var(--color-grey-dark-1);
	text-transform: uppercase;
	font-weight: 700;
}
.industries__list-container a .content hr {
	width: 5rem;
	margin-top: 2rem;
}
.industries__list-container a .content p {
	font-size: 1.4rem;
	margin-top: 2rem;
	color: var(--color-grey-dark-1);
}
.industries__list-container a .content span {
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
	margin-top: 4.5rem;
	display: flex;
	align-items: center;
	text-transform: uppercase;
}

/* SINGLE-INDUSTRY */
.single-industry__cover {
	height: 80vh;
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.single-industry__cover h1 {
	font-size: 6.5rem;
	color: #fff;
	text-transform: uppercase;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, .8);
}
.single-industry__about {
	display: flex;
	padding: 4.5rem 0;
	background-color: #fff;
}
.single-industry__about-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
}
.single-industry__about-container p {
	flex: 1;
	margin-right: 4.5rem;
	font-size: 1.4rem;
	white-space: pre-line;
	text-align: justify;
	color: var(--color-grey-dark-1);
}
.single-industry__about-container picture {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
}
.single-industry__about-container picture img {
	width: 100%;
}
.single-industry__products {
	display: flex;
	padding: 9rem 0;
}
.single-industry__products-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.single-industry__products-container h3 {
	font-size: 3.5rem;
	color: var(--color-primary);
	text-align: center;
	text-transform: uppercase;
}
.single-industry__products-container ul {
	margin-top: 2.5rem;
	display: flex;
	flex-wrap: wrap;
}

/* PRODUCT */
.product {
	flex: 1 0 30%;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	margin: 1rem;
}
.product > picture {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 350px;
	overflow: hidden;
}
.product > picture img {
	width: 100%;
}
.product__container {
	background-color: var(--color-grey-dark-1);
	padding: 2rem;
	display: flex;
	flex-direction: column;
	flex: 1;
}
.product__container h4 {
	font-size: 2rem;
	color: #fff;
}
.product__container p {
	font-size: 1.4rem;
	margin-top: 2rem;
	text-align: justify;
	color: #fff;
}
.product__container a {
	margin-top: auto;
	margin-left: auto;
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	text-decoration: none;
	color: var(--color-primary);
	font-weight: 600;
}

/* SINGLE-PRODUCT */
.single-product {
	min-height: 100vh;
	display: flex;
	padding-top: 11rem;
}
.single-product__container {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
	padding-bottom: 4.5rem;
}
.single-product__container h2 {
	padding: 1rem;
	font-size: 2.5rem;
	color: var(--color-primary);
	text-transform: uppercase;
	border-bottom: 2px solid var(--color-primary);
}
.single-product__gallery {
	display: flex;
	overflow-x: auto;
	height: 300px;
	margin-top: 2rem;
}
.single-product__gallery picture {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 2rem;
}
.single-product__gallery picture img {
	height: 100%;
}
.single-product__container h1 {
	font-size: 4rem;
	color: var(--color-primary);
	text-align: center;
	padding: 2.5rem;
	text-transform: uppercase;
}
.single-product__container p {
	font-size: 1.4rem;
	text-align: justify;
	margin-top: 2rem;
}
.single-product__container ul {
	padding: 2rem;
}
.single-product__container ul li {
	margin-bottom: 1rem;
	font-size: 1.4rem;
	text-align: justify;
}

/* BRAND */
.brand {
	display: flex;
	flex-direction: column;
	padding: 2rem 0;
}
.brand span {
	font-size: 1.4rem;
	text-transform: uppercase;
}
.brand picture {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 10rem;
	margin-top: 1rem;
}
.brand picture img {
	height: 100%;
}

/* SERVICES */
.services {
	min-height: 100vh;
	display: flex;
}
.services__container {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	padding: 12.5rem 2.5rem 2.5rem;
}
.services__container ul {
	display: flex;
	flex-direction: column;
}
.services__container form {
	display: flex;
	width: 100%;
	background-color: #fff;
	overflow: hidden;
	border-radius: 4rem;
	margin-bottom: 2rem;
	align-items: center;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
}
.services__container form input {
	flex: 1;
	border: none;
	padding: 1rem 2rem;
	background-color: transparent;
	outline: none;
	color: var(--color-grey-dark-1);
}
.services__container form i {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .5rem 1rem;
}
.services__container .product {
	flex-direction: row;
	flex: 1;
	max-width: unset !important;
}
.services__container .product picture {
	flex: 1;
}
.services__container .product .product__container {
	flex: 2;
}

/* ABOUT */
.about {
	min-height: 100vh;
}
.about__cover {
	height: 70vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url('/images/ede10.png');
	background-size: cover;
	background-position: top;
}
.about__cover h1 {
	font-size: 4.5rem;
	color: var(--color-primary);
	text-transform: uppercase;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, .6);
}
.about__details {
	display: flex;
	padding: 4.5rem 0;
}
.about__details-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.about__details-container h3 {
	font-size: 2rem;
	margin-bottom: 2rem;
	text-transform: uppercase;
	color: var(--color-primary);
}
.about__details-container p {
	font-size: 1.4rem;
	margin-bottom: 4.5rem;
}

/* CONTACT */
.contact {
	padding-top: 11rem;
	display: flex;
	min-height: 100vh;
}
.contact__container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
	padding: 2rem;
}
.contact__container .content {
	flex: 1;
}
.contact__container .content h1 {
	padding: 1rem;
	font-size: 2.5rem;
	text-transform: uppercase;
	color: var(--color-grey-dark-1);
	letter-spacing: 3px;
	border-bottom: 3px solid var(--color-grey-dark-1);
}
.contact__container form {
	flex: 1;
}
.contact__container .content h3 {
	font-size: 2rem;
	padding: 1rem;
	color: var(--color-primary);
}
.contact__container .content ul {
	padding: 1rem 2rem;
	font-size: 1.4rem;
}
.contact__container .content ul li {
	margin-bottom: 1rem;
}
.contact__container .content p {
	font-size: 1.4rem;
	margin-bottom: 1rem;
}
.contact__container .content a {
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
	text-decoration: none;
	margin-bottom: 1rem;
}

/* RESPONSIVE */
@media only screen and (max-width: 48em) {
	.home__contact-container {
		flex-direction: column-reverse;
		width: 100%;
		padding: 2.5rem;
	}
}

@media only screen and (max-width: 26.5625em) {
	.header__top ul {
		display: none;
	}
	.footer__top {
		flex-direction: column;
		padding: 2.5rem;
	}
	.home__social-container {
		flex-direction: column;
		padding: 0 2.5rem;
	}
	.home__separator-container {
		padding: 2.5rem;
		flex-direction: column;
	}
	.home__highlights {
		flex-direction: column;
	}
	.home__highlights a {
		margin-left: 0 !important;
	}
	.header__bottom > ul {
		flex: 1;
	}
	.industries__list-container {
		flex-direction: column;
		padding: 2.5rem;
	}
	.single-industry__about-container {
		flex-direction: column;
		padding: 2.5rem;
	}
	.single-industry__about-container p {
		margin-right: 0;
		margin-bottom: 2rem;
	}
	.single-industry__products-container ul {
		flex-direction: column;
	}
	.products__container ul {
		flex-direction: column;
		align-items: center;
	}
	.contact__container {
		flex-direction: column;
	}
	.home__contact-middle-container form {
		width: 100%;
	}
	.home__contact-middle-container {
		width: 100%;
	}
	.home__social-item {
		margin-bottom: 2rem;
		margin-right: 0 !important;
	}
	.home__products-container {
		flex-direction: column;
		overflow-y: unset;
		align-items: center;
	}
	.product {
		flex: unset;
	}
	.industries__list-container a {
		flex: unset;
	}
	.single-industry__cover h1 {
		font-size: 4.5rem;
	}
}